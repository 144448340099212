import React from "react";
import { RichText } from "prismic-reactjs";
import tw from "twin.macro";
import Testimonial from "../Testimonial";

const TestimonialsWrapper = tw.section`
  max-w-lg mx-auto px-8 md:max-w-screen-xl md:flex md:flex-row md:flex-wrap md:px-6 lg:px-8
`;

const Testimonials = ({ slice }) => {
  return (
    <TestimonialsWrapper>
      {slice.fields &&
        slice.fields.map(({ testimonials }, idx) => {
          return (
            <Testimonial
              key={`testimonial-${idx}`}
              right={idx % 2}
              quote={RichText.asText(testimonials.quote)}
              author={testimonials.author}
              authorInfo={testimonials.bio}
              authorBio={testimonials.photo.url}
            />
          );
        })}
    </TestimonialsWrapper>
  );
};

export default Testimonials;
