import React from "react";
import { RichText } from "prismic-reactjs";
import tw, { styled } from "twin.macro";
import Navigation from "../Navigation";
import Pattern from "../../img/bg-pattern.png";
import { H1, P } from "../../utils/helpers";
import AppStoreLinks from "../AppStoreLinks";
import TextApp from "../TextApp";

const HeaderWrapper = tw.div`
  relative bg-gray-500
`;

const Container = tw.div`
  block relative inset-y-0 h-full w-full flex-none pb-8 pt-28 md:pt-36 lg:pt-48 pb-20 md:pb-28 overflow-hidden
`;

const Content = styled.div`
  ${tw`relative text-center mb-8 text-white z-20 px-4 md:px-8 lg:max-w-screen-xl mx-auto`}
  & P {
    ${tw`max-w-3xl mx-auto lg:text-lg`}
  }
`;

const BGOverlay = styled.div`
  ${tw`absolute w-full h-full top-0 left-0 z-10 bg-black opacity-75`}
  background-image: linear-gradient(180deg, rgba(0,0,50,0.7) 0%, rgba(34,39,97,0.7) 100%), url("${Pattern}");
  background-repeat: repeat;
`;

const BGImage = tw.img`
  absolute w-full h-full top-0 inset-x-0 object-cover
`;

const HeaderBlock = ({ slice }) => (
  <HeaderWrapper>
    <Navigation />
    <Container>
      <BGImage src={slice.primary.header_background.url} alt="" />
      <BGOverlay />
      <Content>
        <H1>{RichText.asText(slice.primary.page_header)}</H1>
        <P>{RichText.asText(slice.primary.intro__copy)}</P>
        <TextApp
          light
          trackPage={slice.primary.page_header}
          trackLocation="header"
        />
        <AppStoreLinks />
      </Content>
    </Container>
  </HeaderWrapper>
);

export default HeaderBlock;
