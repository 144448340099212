import React from "react";
import Img from "gatsby-image";
import { RichText } from "prismic-reactjs";
import tw, { styled } from "twin.macro";
import { H3, clipPath } from "../../utils/helpers";

const SplitWrapper = styled.div`
  ${tw`relative mx-auto py-8 md:py-16 text-center lg:text-left lg:py-8 md:flex my-6 lg:my-12 items-center justify-around max-w-7xl`}
`;

const SplitImage = styled.div`
  ${tw`relative w-full md:w-2/5 flex-none mb-6 md:mb-0`}
  clip-path: ${clipPath.lg};
  max-height: 50vh;
  & Img {
    ${tw`object-cover`}
  }
`;

const positionBlock = (position) => {
  switch (position % 2) {
    case 0:
      return tw`md:order-first`;
    case 1:
      return tw`md:order-last`;
    default:
      return null;
  }
};

const SplitBody = styled.div`
  ${tw`max-w-full flex flex-col justify-center px-4 md:px-12 md:w-2/5 text-left items-start`}
  ${(props) => positionBlock(props.pos)}
`;

const SplitSection = ({ slice }) => (
  <>
    {slice.fields &&
      slice.fields.map(({ header, imageSharp, text }, idx) => (
        <SplitWrapper>
          <SplitImage>
            <Img fluid={imageSharp.childImageSharp.fluid} />
          </SplitImage>
          <SplitBody pos={idx}>
            <H3>{RichText.asText(header)}</H3>
            <RichText render={text} />
          </SplitBody>
        </SplitWrapper>
      ))}
  </>
);

export default SplitSection;
