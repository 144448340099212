import React from "react";
import tw, { styled } from "twin.macro";
import { H2, CutCorner, BorderButton } from "../utils/helpers";

const CtaWrapper = styled(CutCorner)`
  ${tw`relative mx-auto text-center py-12 px-4 w-10/12 sm:px-6 lg:py-20 lg:px-8 flex flex-col items-center bg-cover md:max-w-7xl mt-4 mb-12 md:my-12`};
`;

const CtaBlock = ({ children, cta, bg, ctalink }) => (
  <CtaWrapper shadow>
    <H2>{children}</H2>
    <BorderButton to={ctalink}>{cta}</BorderButton>
  </CtaWrapper>
);

export default CtaBlock;
