import React from "react";
import tw, { styled } from "twin.macro";
import { H3, P, BevelBorder, BorderButton } from "../utils/helpers";
import Check from "../img/checkmark.svg";

const Card = styled(BevelBorder)`
  ${tw`mb-6 lg:mb-0 text-white p-6 lg:p-10 text-left text-white`}
  & H3 {
    ${tw`text-white font-semibold mb-2`}
  }
`;

const Ideal = tw.p`
  mt-2 text-base
`;

const Rates = tw.div`
  py-4 border-t border-b border-linen my-4
`;

const Price = tw.div`
  flex items-baseline text-4xl leading-none font-medium mb-1
`;

const Slashed = tw.div`
  items-baseline text-4xl leading-none font-medium mb-1 line-through
`;

const Unit = tw.span`
  ml-1 text-2xl leading-8 font-light text-gray-200
`;

const PerkTitle = tw(P)`
  mt-4 leading-7 font-medium
`;

const Perk = styled(P)`
  ${tw`flex items-start text-xl leading-6 my-2`}
  & svg {
    ${tw`h-6 w-6 mr-3`}
  }
`;

const PriceCard = ({
  tier,
  ideal,
  rate,
  annual,
  showAnnual,
  hourly,
  hourlySlashed,
  perks,
  link,
  cta,
  children,
}) => (
  <Card>
    <H3>{tier}</H3>
    <Ideal>{ideal}</Ideal>
    <Rates>
      {!showAnnual && (
        <Price>
          {rate}
          <Unit>/month</Unit>
        </Price>
      )}
      {annual !== null && showAnnual && (
        <Price>
          {annual}
          <Unit>/year</Unit>
        </Price>
      )}
      { hourlySlashed && <Price>
        $<Slashed style={{textDecorationColor: "#ef4444"}}>{hourlySlashed}</Slashed>&nbsp;{hourly}
        <Unit>/hr driven</Unit>
      </Price>}
      {!hourlySlashed && <Price>
        ${hourly}
        <Unit>/hr driven</Unit>
      </Price>}
    </Rates>
    {perks.length > 0 ? (
      <PerkTitle>Available at this level</PerkTitle>
    ) : (
      <P>All levels include complimentary phone concierge service.</P>
    )}
    {perks.length > 0 &&
      perks.map(({ perk }, idx) => (
        <Perk key={`perk-${idx}`}>
          <Check />
          {perk}
        </Perk>
      ))}
    {children}
    <BorderButton to={link} tw="md:hidden" center>
      {cta}
    </BorderButton>
  </Card>
);

export default PriceCard;
