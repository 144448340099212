import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { RichText } from "prismic-reactjs";
import tw, { styled } from "twin.macro";
import { H2, P } from "../../utils/helpers";
import PriceCard from "../PriceCard";
import TextApp from "../TextApp";

const PricingWrapper = styled.section`
  background-image: url("${(props) =>
    props.bg || "/assets/img/fabric@2x.jpg"}");
  ${tw`mx-auto text-center py-12 px-4 sm:px-6 lg:py-20 lg:px-8 flex flex-col items-center bg-cover text-white`};
`;

const PricingOptions = styled.div`
  ${tw`max-w-md mx-auto md:max-w-5xl md:grid md:gap-5 items-start mt-8 pb-12 sm:mt-12 sm:pb-16 lg:mt-16 lg:pb-16`}
  ${({ count }) => {
    if (count === 1) return tw`md:grid-cols-1`;
    if (count === 2) return tw`md:grid-cols-2`;
    if (count === 3) return tw`md:grid-cols-3`;
    if (count === 4) return tw`md:grid-cols-4`;
    if (count === 5) return tw`md:grid-cols-5`;
    if (count === 6) return tw`md:grid-cols-6`;
    if (count === 7) return tw`md:grid-cols-7`;
    if (count === 8) return tw`md:grid-cols-8`;
    if (!count) return tw`md:grid-cols-1`;
  } }
`;

const PricingSection = ({ slice, data, bg, trackPage, cta }) => {
  if (slice && slice.primary.show_pricing_module === false) return null;
  return (
    <PricingWrapper bg={bg}>
      <H2 style={tw`mb-8`}>Activate your Free Trial Today!</H2>
      <P style={tw`text-xl mb-4 max-w-xl`}>
        New members can try Jeevz risk-free for 7 days after the completion of your first ride.
      </P>
      <P style={tw`text-xl mb-4 max-w-xl text-fire`}>Risk Free, Cancel Anytime.</P>

      <StaticQuery
        query={`${pricesQuery}`}
        render={(data) => (
          <PricingOptions count={data.prismic.allPrice_tiers.edges.length}>
            {data.prismic.allPrice_tiers.edges.reverse().map(({ node }, idx) => {
              return (
                <div>
                  <PriceCard
                    key={`price-tier-${idx}`}
                    tier={RichText.asText(node.title)}
                    ideal={node.ideal_if}
                    rate={"$" + node.monthly_price}
                    annual={
                      node.annual_price !== null
                        ? "$" + node.annual_price
                        : null
                    }
                    showAnnual={node.show_annual}
                    hourly={node.hourly_price}
                    hourlySlashed={node.hourly_price_slashed}
                    link="http://onelink.to/rutnj9"
                    cta="Get the app"
                    perks={node.perks}
                  />
                </div>
              );
            })}
          </PricingOptions>
        )}
      />
      <TextApp trackPage={trackPage} trackLocation="pricing" cta={cta} light />
    </PricingWrapper>
  );
};

export default PricingSection;

const pricesQuery = graphql`
  query PriceTierQuery {
    prismic {
      allPrice_tiers(sortBy: hourly_price_DESC) {
        edges {
          node {
            hourly_price
            hourly_price_slashed
            ideal_if
            annual_price
            monthly_price
            title
            show_annual
            perks {
              perk
            }
          }
        }
      }
    }
  }
`;
