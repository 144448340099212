import React from "react";
import tw, { styled } from "twin.macro";
import { track } from "../analytics";
import { Formik, Form, Field, ErrorMessage } from "formik";
import 'core-js/es6/promise';
import 'core-js/es6/set';
import 'core-js/es6/map';
import * as yup from 'yup';
import "yup-phone";
import { SolidButton, BorderButton, FormMsg, P } from "../utils/helpers.js";
import qs from 'qs';

const TextWrapper = styled.div`
  ${tw`hidden md:flex md:flex-col md:mt-8 md:relative md:z-50 md:w-full`}
  ${(props) =>
    props.itemsStart
      ? tw`md:items-start`
      : tw`md:items-center md:mx-auto md:text-center`}
`;

const TextCTA = tw(P)`
  uppercase tracking-wide mb-3
`;

const FormField = tw(Field)`
  border border-2 border-ink rounded-md px-4 py-3 text-ink mb-4 w-80 max-w-full
`;

const Label = tw.label`
  hidden
`;

const DownloadButton = styled.div`
  ${tw`md:hidden`}
  ${(props) => props.showButton ? tw`relative mx-auto text-center mb-6` : tw`hidden`}
`;

const SubmitButton = SolidButton.withComponent("button");
const SubmitBorder = BorderButton.withComponent("button");

// Twilio serverless function URL -- Jeevz account
// const functionURL = "https://sandstorm-chameleon-2944.twil.io/send-app-link";
// const functionURL = "https://app-download-link-8353.twil.io/app-download-link";

// const functionURL = "http://localhost:5100/leads"
const functionURL = "https://api.jeevz.com/leads"

const validationSchema = yup.object().shape({
  phone_number: yup.string()
    .phone("US")
    .required("Please enter a valid US phone number."),
});

const initialValues = {
  phone_number: "",
  success: false,
};

const TextApp = ({
  trackLocation,
  trackPage,
  cta,
  itemsStart,
  light,
  showButton,
}) => {
  const handleSubmit = async (values) => {
    const to = values.phone_number;
    const body = {
      website_visit: true,
      client: {
        phone_number: to,
        lead_source: "homepage_desktop",
      },
    }

    const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });

    for (const key in params) {
      if (key.includes("utm_")) {
        if (!body['client']['utms_attributes']) {
          body['client']['utms_attributes'] = [{}]
        }
        body['client']['utms_attributes'][0][key.replace('utm_', '')] = params[key]
      }
    }

    const response = await fetch(functionURL, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization": "Bearer 71bd81746946d10d8d0bc89063b707241355d44344a75057d883d879d4cd9ec8bde8722f8ae66c3dcee937d40255f8cafaa6799a2ebb7f68607943b0d8dfd007"
      },
      // mode: "no-cors",
      body: JSON.stringify(body),
    });

    if (response.status === 200) {
      initialValues.phone_number = "";
      initialValues.success = true;

      track("Text button clicked", {
        page: trackPage,
        location: trackLocation,
        cta: cta || "none",
      });
    } else {
      const json = await response.json();
      this.setState({
        error: json.error,
        submitting: false,
      });
    }
  };

  return (
    <div tw="w-full">
      <TextWrapper itemsStart={itemsStart}>
        {cta && <TextCTA>{cta}</TextCTA>}

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form
              name="sms-app"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              method="post"
            >
              <div>
                <Label htmlFor="phone_number">Phone number</Label>
                <FormField
                  type="tel"
                  name="phone_number"
                  placeholder="Enter your phone number"
                />
                <ErrorMessage name="phone_number">
                  {() => (
                    <FormMsg light={light}>
                      Please enter a valid US phone number
                    </FormMsg>
                  )}
                </ErrorMessage>
              </div>
              {!light && (
                <SubmitButton
                  aria-label={initialValues.success ? "Success!" : "Submit form"}
                  type="submit"
                  disabled={initialValues.success || isSubmitting}
                >
                  { initialValues.success ? "Success!" : "Text me the app" }
                </SubmitButton>
              )}
              {light && (
                <SubmitBorder
                  aria-label={initialValues.success ? "Success!" : "Submit form"}
                  type="submit"
                  disabled={initialValues.success || isSubmitting}
                >
                  { initialValues.success ? "Success!" : "Text me the app" }
                </SubmitBorder>
              )}
            </Form>
          )}
        </Formik>
      </TextWrapper>
      <DownloadButton showButton={showButton}>
        <SolidButton
          to="https://l.jvz.app/tjSK/Jeevz"
          onClick={() =>
            track("Text button clicked", {
              page: trackPage,
              location: trackLocation,
            })
          }
        >
          Get the app
        </SolidButton>
      </DownloadButton>
    </div>
  );
};

export default TextApp;
