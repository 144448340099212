import React from "react";
import tw, { styled } from "twin.macro";
import { H4, P, clipPath } from "../utils/helpers";
import Quotes from "../img/quotes.svg";

const TestimonialWrapper = styled.div`
  ${tw`relative py-12 px-4 sm:px-6 md:flex md:flex-col md:py-16 md:px-24 flex-1`}
  min-width: 50%;
  ${(props) => props.right && tw`md:border-l md:border-indigo-200`}
`;

const TestimonialBody = tw.blockquote`
  mt-8 md:flex-grow md:flex md:flex-col relative
`;

const Quote = tw(P)`
  relative text-ink font-light z-20 text-xl
`;

const QuoteIcon = tw(Quotes)`
  absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-indigo-200 z-0
`;

const Attribution = styled.div`
  ${tw`mt-8 flex flex-row items-center md:items-baseline`}
  & P,
  H4 {
    ${tw`relative md:bottom-4 ml-2 text-lg md:inline`}
  }
`;

const ProfilePic = styled.img`
  ${tw`w-16 h-16 object-cover mr-2`}
  clip-path: ${clipPath.sm};
`;

const Testimonial = ({ quote, author, authorInfo, authorBio, right }) => (
  <TestimonialWrapper right={right}>
    <TestimonialBody>
      <QuoteIcon />
      <Quote>{quote}</Quote>
      <Attribution>
        {authorBio && <ProfilePic src={authorBio} alt="" />}
        <span>
          <H4>{author}</H4>
          <P>
            <span tw="hidden md:inline">/ </span>
            {authorInfo}
          </P>
        </span>
      </Attribution>
    </TestimonialBody>
  </TestimonialWrapper>
);

export default Testimonial;
