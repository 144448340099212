import React from "react";
import { RichText } from "prismic-reactjs";
import tw from "twin.macro";
import { H2 } from "../../utils/helpers";

const LifestyleWrapper = tw.div`
  relative w-full py-8 px-5 md:p-40 lg:p-64
`;

const BackgroundImage = tw.img`
  absolute inset-0 w-full h-full object-cover
`;

const BGOverlay = tw.div`
  absolute w-full h-full top-0 left-0 z-0 bg-black opacity-25
`;

const LifestyleImage = ({ slice }) => (
  <LifestyleWrapper>
    <BackgroundImage src={slice.primary.jumbo_image.url} />
    <BGOverlay />
    <H2>{RichText.asText(slice.primary.headline)}</H2>
  </LifestyleWrapper>
);

export default LifestyleImage;
