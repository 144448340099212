import React from "react";
import tw from "twin.macro";

const Links = tw.div`
  flex flex-col items-center md:hidden my-8
`;

const Link = tw.a`
  block w-48 mt-3 max-w-full
`;

const Apple = tw.img`
  w-9/12 mx-auto
`;

const Google = tw.img`
  w-10/12 mx-auto
`;

const AppStoreLinks = ({ oneLink }) => (
  <Links>
    <Link href="https://apps.apple.com/us/app/jeevz-personal-driver/id1518758250?mt=8">
      <Apple
        src={'/assets/img/appstore.png'}
        alt="Get Jeevz on the App Store"
      />
    </Link>
    <Link href="https://play.google.com/store/apps/details?id=com.jeevz.jeevz&hl=en">
      <Google
        alt="Get Jeevz on Google Play"
        src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
      />
    </Link>
  </Links>
);

export default AppStoreLinks;
