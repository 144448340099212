import React from "react";
import { RichText } from "prismic-reactjs";
import tw from "twin.macro";
import { H3, CutCornerBorder } from "../../utils/helpers";
import Feature from "./Feature";
import TextApp from "../TextApp";

const FeaturesWrapper = tw.section`
  relative
`;

const FeaturesContainer = tw.div`
  relative -mt-8 md:-mt-20 text-ink pb-10 md:pb-20
`;

const Features = tw(CutCornerBorder)`
  shadow relative mx-auto max-w-lg md:max-w-5xl w-11/12 md:w-5/6 xl:w-3/4 p-5 md:p-10 lg:p-16 lg:pt-10 items-center z-10 -mt-10
`;

const FeaturesHeader = tw(H3)`
  z-50 text-center mb-10 max-w-3xl mx-auto
`;

const FeatureGrid = tw.div`
  grid grid-cols-1 md:grid-cols-2 gap-8 relative z-20
`;

const FeatureSection = ({ slice, trackPage }) => (
  <FeaturesWrapper>
    <FeaturesContainer>
      <Features>
        {slice.primary.headline !== null && (
          <FeaturesHeader>
            {RichText.asText(slice.primary.headline)}
          </FeaturesHeader>
        )}
        <FeatureGrid>
          {slice.fields &&
            slice.fields.map(({ feature }, idx) => (
              <Feature
                icon={feature.feature_icon}
                feature={RichText.asText(feature.feature_title)}
                description={feature.feature_description}
                key={`feature-${idx}`}
              />
            ))}
        </FeatureGrid>
        <TextApp
          trackLocation="features_grid"
          trackPage={trackPage}
          showButton
        />
      </Features>
    </FeaturesContainer>
  </FeaturesWrapper>
);

export default FeatureSection;
