import React, { useState } from "react";
import { RichText } from "prismic-reactjs";
import { useSwipeable } from "react-swipeable";
import tw, { styled } from "twin.macro";
import { H2, P, clipPath } from "../../utils/helpers";

const CarouselWrapper = tw.div`
  relative w-full h-screen px-5 md:p-64 md:h-auto lg:py-80
`;

const Slide = styled.div`
  ${tw`absolute inset-0 py-8 px-12 md:p-40 lg:p-64 text-white text-center transform transition duration-300 h-full flex flex-col justify-center`}
  ${(props) => (props.isActive ? tw`opacity-100` : tw`opacity-0`)}
`;

const BackgroundImage = tw.img`
  absolute inset-0 w-full h-full object-cover
`;

const BGOverlay = tw.div`
  absolute w-full h-full top-0 left-0 z-0 bg-black opacity-50
`;

const NextButton = styled.button`
  clip-path: ${clipPath.sm};
  ${tw`bg-fire hover:bg-white text-white hover:text-ink absolute w-8 h-8 md:w-12 md:h-12 inline-flex items-center text-center md:text-xl z-30 cursor-pointer right-2 md:right-10 top-1/2 px-2 md:px-4 transition duration-300 ease-in-out`}
`;

const PrevButton = styled(NextButton)`
  ${tw`left-2 md:left-10`}
  transform: scaleX(-1);
`;

const LifestyleImage = ({ slice }) => {
  const [active, setActive] = useState(0);

  const nextSlide = () => {
    setActive((active) => active + 1);
  };

  const prevSlide = () => {
    active === 0
      ? setActive((active) => slice.fields.length - 1)
      : setActive((active) => active - 1);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => nextSlide(),
    onSwipedRight: () => prevSlide(),
    preventDefaultTouchmoveEvent: true,
    // trackMouse: true,
  });
  return (
    <CarouselWrapper>
      <BGOverlay />
      {slice.fields.map(({ image, use_case_title, use_case_text }, idx) => (
        <Slide
          key={`use-case-${idx}`}
          isActive={idx === Math.abs(active) % slice.fields.length}
          {...handlers}
        >
          <BackgroundImage src={image.url} />
          <BGOverlay />
          <H2 tw="text-4xl">{use_case_title}</H2>
          <P tw="md:text-xl">{RichText.asText(use_case_text)}</P>
        </Slide>
      ))}
      {slice.fields.length > 1 && (
        <PrevButton onClick={prevSlide}>→</PrevButton>
      )}
      {slice.fields.length > 1 && (
        <NextButton onClick={nextSlide}>→</NextButton>
      )}
    </CarouselWrapper>
  );
};

export default LifestyleImage;
