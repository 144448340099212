import React from "react";
import { RichText } from "prismic-reactjs";
import tw from "twin.macro";
import Feature from "./Feature";

const FeaturesWrapper = tw.section`
  relative
`;

const Features = tw.div`
  flex flex-col p-4 sm:flex-row md:p-6 lg:p-12 mx-auto max-w-7xl
`;

const FeatureStrip = ({ slice }) => {
  return (
    <FeaturesWrapper>
      <Features>
        {slice.fields &&
          slice.fields.feature &&
          slice.fields.map(({ feature }, idx) => (
            <Feature
              icon={feature.feature_icon}
              feature={RichText.asText(feature.feature_title)}
              description={feature.feature_description}
              key={`feature-${idx}`}
            />
          ))}
      </Features>
    </FeaturesWrapper>
  );
};

export default FeatureStrip;
